import { Button, Typography } from 'antd';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import EmptyLayout from '../../components/layout/EmptyLayout';
import SearchEngineOptimization from '../../components/utility/seo';

const { Text, Title } = Typography;

const UpdatePasswordSuccess = () => {
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get('orderId');

  return (
    <EmptyLayout>
      <SearchEngineOptimization title="Password Updated" />
      <div className="flex flex-col items-center">
        <StaticImage
          src="../images/circle-check.png"
          alt="x-circle"
          placeholder="tracedSVG"
          className="w-[48px] h-[48px]"
          objectFit="contain"
        />
        <Title className="!font-semibold mt-5 mb-4 !text-light-title" level={3}>
          เปลี่ยนรหัสผ่านสำเร็จ
        </Title>
        <Text className="!text-light-primary text-center">
          รหัสผ่านของคุณได้ถูกเปลี่ยนเรียบร้อยแล้ว
        </Text>
        <Link to={`/sign-in`}>
          <Button type="primary" className="mt-6">
            เข้าสู่ระบบ
          </Button>
        </Link>
      </div>
    </EmptyLayout>
  );
};

export default UpdatePasswordSuccess;
